/**
 * URLからFileを生成
 * note: client only
 */
export const getFileByURL = async (
  url: string,
  name?: string
): Promise<File | null> => {
  try {
    if (process.server) throw new Error('genereateFile is client only')

    const res = await fetch(url)
    const blob = await res.blob()
    const ext = getExtFromType(url)
    const fileName = name || `newFile.${ext}`

    const file = new File([blob], fileName, { type: blob.type })
    return file
  } catch (e) {
    console.error(e)
    return null
  }
}

/**
 * URLから拡張子取得
 */
export const getExtFromType = (url: string) => {
  // queryを除去
  const path = url.split('?')[0]
  return path?.split('.').pop() ?? ''
}

/**
 * fileからbase64取得
 */
export const getBase64ByFile = (file: File): Promise<string | undefined> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    // 画像のBufferを取得してemit
    reader.onload = (e) => {
      const result = e?.target?.result
      if (typeof result !== 'string') {
        throw new TypeError('Failed to get base64')
      }
      resolve(result)
    }

    reader.readAsDataURL(file)
  })
}
